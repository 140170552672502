import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ArrowBackIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useGetOrganizations, useCreateOrganization, useCreateUser, getUser } from "../apiCalls/userApiCalls";
import Papa from "papaparse";
import Loader from "../components/Loader";
import { Visibility, VisibilityOff } from "@mui/icons-material"; // Import icons


export default function ImportUsers() {
  const [csvData, setCsvData] = useState([]);
  const [newOrganization, setNewOrganization] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editingUser, setEditingUser] = useState(null); // Track the user being edited
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const navigate = useNavigate();

  const { currentUser } = useSelector((state) => state.userSlice) || null;
  const token = currentUser?.token;

  const { isLoading: isOrganizationsLoading, data: organizations } = useGetOrganizations(token);

  const { mutate: createUserMutate, isLoading: isCreateUserLoading } = useCreateUser();

  const { mutate: createOrganizationMutate, isLoading: isCreateOrganizationLoading } =
    useCreateOrganization();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      transformHeader: (header) => {
        return header
          .replace(/\(.*?\)/g, "") // Remove text within parentheses
          .replace(/[^a-zA-Z0-9 ]/g, "") // Remove special characters
          .trim(); // Trim whitespace
      },
      complete: async (results) => {
        const filteredData = results.data
          .filter((row) =>
            Object.values(row).some((value) => value && value.toString().trim() !== "")
          )
          .map((row) => ({
            ...row,
            "Hourly Rate": parseFloat(row["Hourly Rate"]?.replace("$", "").trim()) || 0,
            "Overtime Rate": parseFloat(row["Overtime Rate"]?.replace("$", "").trim()) || 0,
            "Password": row["Password"]?.trim() || "P4ssw0rd!",
            enabled: true, // Add an `enabled` property to each user
          }));

        await fetchExistingUsers(filteredData);
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };

  const fetchExistingUsers = async (users) => {
    const updatedUsers = await Promise.all(
      users.map(async (user) => {
        try {
          const data = await getUser(token, user["Email Address"]);
          console.log("User data:", data);

          if (data?.email === user["Email Address"]) {
            return {
              ...user,
              existingUser: data,
              conflict: true, 
              enabled: false,
            };
          } else {
            return {
              ...user,
              existingUser: null,
              conflict: false,
              enabled: true,
            };
          }
        } catch (error) {
          console.error("Error checking user:", error);
          return user; // Return the user unchanged on error
        }
      })
    );
    console.log("Updated users:", updatedUsers);
    setCsvData(updatedUsers);
  };

  const handleCreateOrganization = () => {
    if (!newOrganization.trim()) return;

    const data = {
      name: newOrganization,
      token,
    };

    createOrganizationMutate(data, {
      onSuccess: (data) => {
        setSelectedOrganization(data?.data?.data?._id || "");
        setNewOrganization("");
      },
      onError: (error) => {
        console.error("Error creating organization:", error);
      },
    });
  };

  const handleCheckboxChange = (index) => {
    setCsvData((prevData) =>
      prevData.map((user, i) =>
        i === index ? { ...user, enabled: !user.enabled } : user
      )
    );
  };

  const handleEditUser = (index) => {
    setEditingUser({ ...csvData[index], index }); // Save the user and their index in the list
  };

  const handleSaveUser = () => {
    setCsvData((prevData) =>
      prevData.map((user, i) =>
        i === editingUser.index ? { ...editingUser } : user
      )
    );
    setEditingUser(null); // Close the modal
  };

  const handleCloseModal = () => {
    setEditingUser(null); // Close the modal without saving
  };

  const handleSubmit = () => {
    if (!selectedOrganization) {
      alert("Please select or create an organization before importing users.");
      return;
    }

    const enabledUsers = csvData.filter((user) => user.enabled); // Only include enabled users

    console.log("Enabled users:", enabledUsers);

    if (enabledUsers.length === 0) {
      alert("No users selected for import.");
      return;
    }

    setIsSubmitting(true);

    enabledUsers.forEach((user) => {
      createUserMutate(
        {
          name: user["Employee Name"],
          email: user["Email Address"],
          employeeCode: user["Employee ID"],
          role: user["Employee Type"]?.toLowerCase() || "worker",
          hourlyRate: user["Hourly Rate"],
          overtimeRate: user["Overtime Rate"],
          phone: user["Phone Number"],
          password: user["Password"],
          organization: selectedOrganization,
          token,
          // update: editingUser.resolveWithCSV,
        },
        {
          onError: (error) => {
            console.error("Error importing user:", error);
          },
        }
      );
    });

    setIsSubmitting(false);
    alert("User import process completed!");
  };

  const enabledUserCount = csvData.filter((user) => user.enabled).length;

  return (
    <div className="flex w-full items-center justify-center">
      <div className="mx-6 md:mx-32 lg:mx-44 xl:mx-64 mt-32 w-full lg:w-2/5">
        <div className="fixed lg:left-20 xl:left-32 mt-2 hidden lg:flex lg:items-center lg:justify-center items-center justify-center w-10 h-10 rounded-full bg-white shadow btn-shadow hover:shadow-s, hover:bg-stone-50 transition-colors">
          <button
            onClick={() => window.history.back()}
            className="w-full h-full rounded-full flex items-center justify-center"
          >
            <ArrowBackIos
              style={{ fontSize: 24, paddingLeft: 6, color: "gray" }}
            />
          </button>
        </div>

        <div className="pt-10 flex flex-col items-center">
          {isOrganizationsLoading ? (
            <Loader />
          ) : (
            <form onSubmit={(e) => e.preventDefault()} className="pb-8">
              <div className="space-y-3 md:space-y-4">
                {/* File Upload */}
                <div className="space-y-2">
                  <label className="text-base md:text-lg text-gray-500">
                    Upload CSV
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="file"
                      accept=".csv"
                      className="outline-none w-full text-base font-medium text-black"
                      onChange={handleFileUpload}
                    />
                  </div>
                </div>

                {/* Organization Selector */}
                <div className="space-y-2">
                  <label className="text-base md:text-lg text-gray-500">
                    Organization
                  </label>
                  {organizations?.data?.data.length > 0 ? (
                    <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                      <select
                        className="outline-none w-full text-base font-medium text-black"
                        value={selectedOrganization}
                        onChange={(e) => setSelectedOrganization(e.target.value)}
                      >
                        <option value="">Select Organization</option>
                        {organizations.data.data.map((org) => (
                          <option key={org._id} value={org._id}>
                            {org.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                      <button
                        type="button"
                        className="px-3 py-2 text-white font-medium rounded-xl bg-[#59c3c2]"
                        onClick={() => navigate("/createOrganization")}
                      >
                        Create Organization
                      </button>
                    </div>
                  )}
                </div>

                {/* New Organization Creation */}
                <div className="space-y-2">
                  <label className="text-base md:text-lg text-gray-500">
                    Create New Organization
                  </label>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="text"
                      className="outline-none w-full text-base font-medium text-black"
                      placeholder="New Organization Name"
                      value={newOrganization}
                      onChange={(e) => setNewOrganization(e.target.value)}
                    />
                    <button
                      type="button"
                      onClick={handleCreateOrganization}
                      className="px-3 py-2 text-white font-medium rounded-xl bg-[#59c3c2]"
                      disabled={isCreateOrganizationLoading}
                    >
                      {isCreateOrganizationLoading
                        ? "Creating..."
                        : "Create"}
                    </button>
                  </div>
                </div>

                {/* User Cards */}
                {csvData.length > 0 && (
                  <div className="space-y-4">
                    <label className="text-base md:text-lg text-gray-500">
                      Review CSV Data
                    </label>
                    {csvData.map((user, index) => (
                      <div key={index} className="rounded-xl p-4 border border-black shadow-md">
                        <div className="flex justify-between items-center">
                          {user.conflict ? (
                            <span className="text-red-500 font-bold">User Already Exists!</span>
                          ) : (
                            <span className="text-green-500 font-bold">No conflict</span>
                          )}
                          <input
                            type="checkbox"
                            checked={user.enabled}
                            disabled={user?.existingUser?.email === user["Email Address"]}
                            onChange={() => handleCheckboxChange(index)}
                            className="h-5 w-5 appearance-none border-2 border-gray-300 rounded bg-white checked:bg-[#59c3c2] checked:border-[#59c3c2] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#59c3c2]"
                          />
                        </div>
                        <div className="flex justify-between items-flex-start">
                          <h3 className="text-lg font-semibold">
                            {user["Employee Name"] || "Unnamed User"}
                          </h3>
                          
                        </div>
                        <p>Email: {user["Email Address"]}</p>
                        <p>Phone: {user["Phone Number"] || "N/A"}</p>
                        <p>Hourly Rate: ${user["Hourly Rate"].toFixed(2)}</p>
                        <p>Overtime Rate: ${user["Overtime Rate"].toFixed(2)}</p>
                        <button
                          type="button"
                          onClick={() => handleEditUser(index)}
                          className="px-3 py-2 text-white font-medium rounded-xl bg-[#59c3c2]"
                        >
                          Edit
                        </button>
                      </div>
                    ))}
                  </div>
                )}

                {/* Submit Button */}
                {csvData.length > 0 && (
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="px-3 py-4 w-full text-white font-medium rounded-xl bg-[#59c3c2]"
                    disabled={isSubmitting || isCreateUserLoading}
                  >
                    {isSubmitting || isCreateUserLoading
                      ? "Importing..."
                      : `Import ${enabledUserCount} Users`}
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>

      {/* Edit Modal */}
      {editingUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4">
            <h2 className="text-lg font-semibold mb-4">
              {editingUser.existingUser ? "Resolve User Conflict" : "Edit User"}
            </h2>

            <div className={`grid ${editingUser.existingUser ? "grid-cols-2 gap-6" : "grid-cols-1"}`}>
              {/* Left: Existing User Data (Only Show if Conflict Exists) */}
              {editingUser.existingUser && (
                <div>
                  <h3 className="text-md font-bold text-gray-700">Existing User</h3>
                  <label className="block">
                    <span className="text-gray-700">Employee Name</span>
                    <input
                      type="text"
                      className="outline-none w-full text-base font-medium text-gray-500 bg-gray-100 border border-black px-4 py-2 rounded-lg"
                      value={editingUser.existingUser?.name || "N/A"}
                      readOnly
                    />
                  </label>

                  <label className="block">
                    <span className="text-gray-700">Id</span>
                    <input
                      type="text"
                      className="outline-none w-full text-base font-medium text-gray-500 bg-gray-100 border border-black px-4 py-2 rounded-lg"
                      value={editingUser.existingUser?._id || "N/A"}
                      readOnly
                    />
                  </label>

                  <label className="block">
                    <span className="text-gray-700">Email</span>
                    <input
                      type="text"
                      className="outline-none w-full text-base font-medium text-gray-500 bg-gray-100 border border-black px-4 py-2 rounded-lg"
                      value={editingUser.existingUser?.email || "N/A"}
                      readOnly
                    />
                  </label>

                  <label className="block">
                    <span className="text-gray-700">Phone</span>
                    <input
                      type="text"
                      className="outline-none w-full text-base font-medium text-gray-500 bg-gray-100 border border-black px-4 py-2 rounded-lg"
                      value={editingUser.existingUser?.phone || "N/A"}
                      readOnly
                    />
                  </label>

                  <label className="block">
                    <span className="text-gray-700">Role</span>
                    <input
                      type="text"
                      className="outline-none w-full text-base font-medium text-gray-500 bg-gray-100 border border-black px-4 py-2 rounded-lg"
                      value={editingUser.existingUser?.role || "N/A"}
                      readOnly
                    />
                  </label>

                  <label className="block">
                    <span className="text-gray-700">Hourly Rate</span>
                    <input
                      type="text"
                      className="outline-none w-full text-base font-medium text-gray-500 bg-gray-100 border border-black px-4 py-2 rounded-lg"
                      value={`$${editingUser.existingUser?.hourlyRate || "0.00"}`}
                      readOnly
                    />
                  </label>

                  <label className="block">
                    <span className="text-gray-700">Overtime Rate</span>
                    <input
                      type="text"
                      className="outline-none w-full text-base font-medium text-gray-500 bg-gray-100 border border-black px-4 py-2 rounded-lg"
                      value={`$${editingUser.existingUser?.overtimeRate || "0.00"}`}
                      readOnly
                    />
                  </label>
                </div>
              )}

              {/* Right: Editable CSV Data */}
              <div>
                <h3 className="text-md font-bold text-gray-700">
                  {editingUser.existingUser ? "CSV Data (Editable)" : "User Details"}
                </h3>
                <label className="block">
                  <span className="text-gray-700">Employee Name</span>
                  <input
                    type="text"
                    className="outline-none w-full text-base font-medium text-black border border-black px-4 py-2 rounded-lg"
                    value={editingUser["Employee Name"]}
                    onChange={(e) =>
                      setEditingUser((prev) => ({
                        ...prev,
                        "Employee Name": e.target.value,
                      }))
                    }
                  />
                </label>
                <label className="block">
                  <span className="text-gray-700">Employee Id</span>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <input
                      type="text"
                      className="outline-none w-full text-base font-medium text-black placeholder-black"
                      value={editingUser["Employee ID"] || ""}
                      onChange={(e) =>
                        setEditingUser((prev) => ({
                          ...prev,
                          "Employee ID": e.target.value,
                        }))
                      }
                    />
                  </div>
                </label>
                <label className="block">
                  <span className="text-gray-700">Email</span>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                  <input
                    type="email"
                      className="outline-none w-full text-base font-medium text-black placeholder-black"
                    value={editingUser["Email Address"]}
                    onChange={(e) =>
                      setEditingUser((prev) => ({
                        ...prev,
                        "Email Address": e.target.value,
                      }))
                    }
                  />
                  </div>
                </label>

                <label className="block">
                  <span className="text-gray-700">Password</span>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black relative">
                  <input
                      type={showPassword ? "text" : "password"} // Toggle between text/password
                      className="outline-none w-full text-base font-medium text-black placeholder-black"
                      value={editingUser["Password"] || "P4ssw0rd!"}
                    onChange={(e) =>
                      setEditingUser((prev) => ({
                        ...prev,
                          "Password": e.target.value,
                      }))
                    }
                  />
                    {/* Eye Icon for Toggle */}
                    <button
                      type="button"
                      className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </button>
                  </div>
                </label>
                <label className="block">
                  <span className="text-gray-700">Role</span>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                    <select
                      className="outline-none w-full text-base font-medium text-black"
                      value={editingUser["Employee Type"] || "worker"}
                      onChange={(e) =>
                        setEditingUser((prev) => ({
                          ...prev,
                          "Employee Type": e.target.value,
                        }))
                      }
                    >
                      <option value="foreman">Foreman</option>
                      <option value="worker">Worker</option>
                      <option value="admin">Admin</option>
                      <option value="super">Super</option>
                      <option value="sales">Sales</option>
                    </select>
                  </div>
                </label>
                <label className="block">
                  <span className="text-gray-700">Hourly Rate</span>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                  <input
                    type="number"
                      className=" w-full outline-none w-full text-base font-medium text-black placeholder-black"
                      value={parseFloat(editingUser["Hourly Rate"]) || 0}
                    onChange={(e) =>
                      setEditingUser((prev) => ({
                        ...prev,
                        "Hourly Rate": parseFloat(e.target.value) || 0,
                      }))
                    }
                  />
            </div>
                </label>
                <label className="block">
                  <span className="text-gray-700">Overtime Rate</span>
                  <div className="rounded-xl py-4 px-5 flex gap-2 border border-black">
                  <input
                    type="number"
                      className="outline-none w-full text-base font-medium text-black placeholder-black"
                      value={editingUser["Overtime Rate"] || "$0.00"}
                    onChange={(e) =>
                      setEditingUser((prev) => ({
                        ...prev,
                        "Overtime Rate": parseFloat(e.target.value) || 0,
                      }))
                    }
                  />
                  </div>
                </label>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end mt-6">
              <button
                type="button"
                onClick={handleCloseModal}
                className="px-4 py-2 text-gray-600 bg-gray-200 rounded-lg mr-2"
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={editingUser?.existingUser?.email === editingUser["Email Address"]}
                onClick={handleSaveUser}
                className="px-4 py-2 text-white bg-[#59c3c2] rounded-lg"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      </div>
  );
}